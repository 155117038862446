import React from "react"
import family from "../assets/img/team-family.jpg"

export default function Who() {
  return (
    <section
      id="section-who"
      className="team split-box top-padding no-padding-bottom"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-left col-xl-6 no-padding">
            <div
              className="padding-height-70 bg-image"
              style={{ backgroundImage: `url(${family})` }}
            />
          </div>
          {/* /.col */}
          <div className="col-right col-xl-6 align-self-center no-padding">
            <div className="col-inner">
              {/* Begin Heading */}
              <div className="heading heading-xlg">
                <h1>
                  <span className="bg-dark text-white">Who</span>
                  <span className="bg-main text-white">We Are</span>
                </h1>
              </div>
              {/* End Heading */}
              <p className="lead-theme">
                Wir sind Andi und Iris Neck, Geschäftsinhaber von intesso
                engineering gmbh und die Initianten von KARTSCHÜÜR.ch. Wir
                wohnen mit unseren vier Kindern in Dachsen, wo auch der Sitz
                unserer Firma und die KARTSCHÜÜR Zuhause sind.
              </p>
              <br />
              {/* <a href="#" className="btn btn-inverse btn-rounded btn-lg">Read More</a> */}
            </div>
            {/* /.col-inner */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  )
}
