import React from "react"
import workbg from "../assets/img/work-bg.jpg"

export default function What() {
  return (
    <section
      id="section-what"
      className="work split-box bg-image"
      style={{ backgroundImage: `url(${workbg})` }}
    >
      {/* Element cover */}
      <div className="cover" />
      <div className="container-fluid text-white">
        <div className="row">
          <div className="col-left col-xl-6 align-self-center no-padding">
            <div className="col-inner">
              {/* Begin Heading */}
              <div className="heading heading-xlg">
                <h1>
                  <span className="bg-white text-dark">What</span>
                  <span className="bg-main text-white">You Can Do</span>
                </h1>
              </div>
              {/* End Heading */}
              <p className="lead-theme">
                Wir haben Games für Jung und Alt, GROSS und klein.
              </p>
              <p className="lead-theme">
                Die Games sind intensiv und dauern jeweils 3 Minuten.
              </p>
              <p className="lead-theme">
                In der Schüürbeiz bist du hautnah dabei, und kannst ein Getränk
                oder einen Imbiss geniessen.
              </p>
              <br />
              {/* <a href="#" class="btn btn-white btn-rounded btn-lg">Read More</a> */}
            </div>{" "}
            {/* /.col-inner */}
          </div>{" "}
          {/* /.col */}
          <div className="col-right col-xl-6">
            <div className="col-inner">
              {/* Begin info box wrap */}
              <div className="info-box-wrapper">
                <div className="row">
                  <div className="col col-12 col-md-6">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-tachometer-alt" />
                      </span>
                      <div className="info-box-heading">
                        <h3>Action</h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>
                          Ob strategisches Fahren oder lieber rasante Aktion
                        </p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                  <div className="col col-12 col-md-6">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-directions" />
                      </span>
                      <div className="info-box-heading">
                        <h3>Drifting</h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>Ob waghalsiges Driften oder gemächliches Cruisen</p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                  <div className="col col-12 col-md-6">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-users" />
                      </span>
                      <div className="info-box-heading">
                        <h3>Together</h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>Ob gegeneinander oder miteinander antreten</p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                  <div className="col col-12 col-md-6">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-car-side" />
                      </span>
                      <div className="info-box-heading">
                        <h3>Go-karts</h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>
                          Ob mit Muskelkraft oder mit wendigen Elektro-Karts
                        </p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                  <div className="col col-12 col-md-6">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-rainbow" />
                      </span>
                      <div className="info-box-heading">
                        <h3>Rainbow</h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>
                          Ob Asteroide Abschiessen oder Regenbogen Einsammeln
                        </p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                  <div className="col col-12 col-md-6">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-rocket" />
                      </span>
                      <div className="info-box-heading">
                        <h3>Boost</h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>Ob mit oder ohne Turbo-Boost</p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                </div>{" "}
                {/* /.row */}
              </div>
              {/* End info box wrap */}
            </div>{" "}
            {/* /.col-inner */}
          </div>{" "}
          {/* /.col */}
        </div>{" "}
        {/* /.row */}
      </div>{" "}
      {/* /.container */}
    </section>
  )
}
