import React from "react"
import asteroid from "../assets/img/games/asteroid.jpg"
import batrace from "../assets/img/games/bat-race.jpg"
import unicorn from "../assets/img/games/unicorn-rainbow.jpg"
import balloon from "../assets/img/games/balloon-hunt.jpg"
import fish from "../assets/img/games/fish-dish.jpg"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import MultiCarousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

export default function Games() {
  return (
    <section id="section-games" className="portfolio no-padding-bottom">
      <div className="container-fluid bg-dark">
        {/* Grid sizer (Do not remove!!!) */}
        <div className="grid-sizer" />
        <div className="heading heading-center heading-md text-white">
          <h1>
            <span className="bg-main">G</span>ames
          </h1>
          <p>
            wähle dein Game <i className="fas fa-arrow-down"></i>
          </p>
        </div>
        {/* Begin portfolio grid box 1 */}
        <MultiCarousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay={true}
          autoPlaySpeed={3000}
          centerMode={false}
          containerClass="container-with-dots"
          draggable
          focusOnSelect={false}
          infinite={true}
          ssr={true}
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 3,
              partialVisibilityGutter: 30,
            },
          }}
          showDots={false}
          slidesToSlide={1}
          swipeable
        >
          <div className="box box-1">
            <div className="box-inner">
              <AnchorLink
                to="/games/asteroid"
                className="portfolio-link inline-popup-trigger"
              />
              <div className="cover" />
              <img className="thumbnail" src={asteroid} alt="" />
              <div className="caption align-center">Asteroid</div>
              <h3 className="entry-count"># 01</h3>
            </div>
          </div>
          {/* End portfolio grid box 1 */}
          {/* Begin portfolio grid box 2 */}
          <div className="box box-2">
            <div className="box-inner">
              <AnchorLink
                to="/games/bat-race"
                className="portfolio-link inline-popup-trigger"
              />
              <div className="cover" />
              <img className="thumbnail" src={batrace} alt="" />
              <div className="caption align-center">Bat Race</div>
              <h3 className="entry-count"># 02</h3>
            </div>
          </div>
          {/* End portfolio grid box 2 */}
          {/* Begin portfolio grid box 3 */}
          <div className="box box-3">
            <div className="box-inner">
              <AnchorLink
                to="/games/unicorn-rainbow"
                className="portfolio-link inline-popup-trigger"
              />
              <div className="cover" />
              <img className="thumbnail" src={unicorn} alt="" />
              <div className="caption align-center">Unicorn Rainbow</div>
              <h3 className="entry-count"># 03</h3>
            </div>
          </div>
          {/* End portfolio grid box 3 */}
          {/* Begin portfolio grid box 4 */}
          <div className="box box-4">
            <div className="box-inner">
              <AnchorLink
                to="/games/balloon-hunt"
                className="portfolio-link inline-popup-trigger"
              />
              <div className="cover" />
              <img className="thumbnail" src={balloon} alt="" />
              <div className="caption align-center">Balloon Hunt</div>
              <h3 className="entry-count"># 04</h3>
            </div>
          </div>
          {/* End portfolio grid box 4 */}
          {/* Begin portfolio grid box 4 */}
          <div className="box box-5">
            <div className="box-inner">
              <AnchorLink
                to="/games/fish-dish"
                className="portfolio-link inline-popup-trigger"
              />
              <div className="cover" />
              <img className="thumbnail" src={fish} alt="" />
              <div className="caption align-center">Fish Dish</div>
              <h3 className="entry-count"># 05</h3>
            </div>
          </div>
          {/* End portfolio grid box 5 */}
        </MultiCarousel>
      </div>
      {/* /.container */}
    </section>
  )
}
