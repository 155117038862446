import React from "react"
import teletop from "../assets/img/media/tele-top.png"
import shf from "../assets/img/media/schaffhauser-fernsehen.png"
import radiomunot from "../assets/img/media/radio-munot.png"
import andelfinger from "../assets/img/media/andelfinger-zeitung.png"
import landbote from "../assets/img/media/der-landbote.png"
import shn from "../assets/img/media/schaffhauser-nachrichten.png"
import bock from "../assets/img/media/bock.png"

import MultiCarousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

export default function Media() {
  return (
    <section id="section-media" className="media bg-white text-dark">
      <div className="container">
        {/* Begin Heading */}
        <div className="media-heading center heading heading-xlg">
          <h1>
            <span className="bg-main text-white">Media</span>
          </h1>
          <br />
          <p>Folgende lokale Medien haben über die KARTSCHÜÜR berichtet</p>
        </div>
        <MultiCarousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay={true}
          autoPlaySpeed={1800}
          centerMode={false}
          draggable
          focusOnSelect={false}
          infinite={true}
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          ssr={true}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 4,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 3,
              partialVisibilityGutter: 30,
            },
          }}
          showDots={false}
          slidesToSlide={1}
          swipeable
        >
          <a
            href="https://www.toponline.ch"
            target="_blank"
            rel="noreferrer"
            className="client-logo"
            title="Tele Top"
          >
            <img className="media-img" src={teletop} alt="" />
          </a>
          <a
            href="http://www.shf.ch/"
            target="_blank"
            rel="noreferrer"
            className="client-logo"
            title="Schaffhauser Fernsehen"
          >
            <img className="media-img" src={shf} alt="" />
          </a>
          <a
            href="https://www.radiomunot.ch"
            target="_blank"
            rel="noreferrer"
            className="client-logo"
            title="Radion Munot"
          >
            <img className="media-img" src={radiomunot} alt="" />
          </a>
          <a
            href="https://www.andelfinger.ch"
            target="_blank"
            rel="noreferrer"
            className="client-logo"
            title="Andelfinger Zeitung"
          >
            <img className="media-img" src={andelfinger} alt="" />
          </a>
          <a
            href="https://www.landbote.ch"
            target="_blank"
            rel="noreferrer"
            className="client-logo"
            title="Der Landbote"
          >
            <img className="media-img" src={landbote} alt="" />
          </a>
          <a
            href="https://www.shn.ch"
            target="_blank"
            rel="noreferrer"
            className="client-logo"
            title="Schaffhauser Nachrichten"
          >
            <img className="media-img" src={shn} alt="" />
          </a>
          <a
            href="https://www.bockonline.ch"
            target="_blank"
            rel="noreferrer"
            className="client-logo"
            title="Bock"
          >
            <img className="media-img" src={bock} alt="" />
          </a>
        </MultiCarousel>
        {/* End content carousel */}
      </div>
      {/* /.container */}
    </section>
  )
}
