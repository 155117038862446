import React from "react"

import config from "../../config"

import Layout from "../components/Layout"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Contact from "../components/Contact"
import Media from "../components/Media"
import Partners from "../components/Partners"
import Games from "../components/Games"
import What from "../components/What"
import ActionMovie from "../components/ActionMovie"
import Intro from "../components/Intro"
import Who from "../components/Who"
import Nav from "../components/Nav"
import Scrolltotop from "../components/Scrolltotop"

class IndexPage extends React.Component {
  state = {
    isMenuOpen: false,
  }

  render() {
    return (
      <Layout>
        <div
          id="preloader"
          className="animated fadeOut"
          style={{
            backgroundColor: "#000",
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            zIndex: 2000,
          }}
        >
          <div className="pulse" />
        </div>
        <div className="border-top" />
        <div className="border-bottom" />
        <div className="border-left" />
        <div className="border-right" />
        <Header
          hamburgerClicked={e =>
            this.setState({ isMenuOpen: !this.state.isMenuOpen })
          }
          isMenuOpen={this.state.isMenuOpen}
        />
        <Nav
          hamburgerClicked={e =>
            this.setState({ isMenuOpen: !this.state.isMenuOpen })
          }
          isMenuOpen={this.state.isMenuOpen}
        />
        <div
          id="body-content"
          className={
            this.props.isMenuOpen
              ? "lateral-menu-is-open"
              : "lateral-menu-is-closed"
          }
        >
          <Intro
            title={config.introTitleIndex}
            subtitle={config.introSubtitleIndex}
            countdown={config.countdown}
            section="/#section-action-movie"
          />
          <ActionMovie />
          <What />
          <Games />
          {/* <Price /> */}
          {/* <Directions /> */}
          <Who />
          <Partners />
          <Media />
          <Contact />
          <Footer />
        </div>
        <Scrolltotop />
      </Layout>
    )
  }
}

export default IndexPage
