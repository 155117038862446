import { OutboundLink } from "gatsby-plugin-gtag"
import React from "react"

export default function ActionMovie() {
  return (
    <section
      id="section-action-movie"
      className="video-demonstration split-box top-padding"
    >
      <div className="container-fluid">
        <div className="row bg-light-gray">
          <div className="col-left col-xl-6 no-padding">
            <div className="col-inner no-padding">
              {/* Begin embed video (more info about reffering embed videos: https://www.feedthebot.com/pagespeed/defer-videos.htm) */}
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  src="https://www.youtube.com/embed/wvccpG_ZtQA"
                  allowFullScreen
                  width={1280}
                  height={720}
                  frameBorder={0}
                  title="action-movie"
                />
              </div>
              {/* End embed video */}
            </div>{" "}
            {/* /.col-inner */}
          </div>{" "}
          {/* /.col */}
          <div className="col-right col-xl-6 align-self-center no-padding">
            <div className="col-inner">
              {/* Begin Heading */}
              <div className="heading heading-xlg">
                <h1>
                  <span className="bg-main text-white">3-2-1</span> Action
                </h1>
              </div>
              {/* End Heading */}
              {/* <h2>Jeden zweiten Donnerstag</h2> */}
              <p className="lead-theme">
                Wir zügeln die Kartschüür auf Marthalen. Ab Herbst sind Events
                in Marthalen möglich. Weitere Infos folgen.
              </p>
              <hr />
              <p className="lead-theme">
                Wenn du einen exklusiven Event in eurer Location haben willst,
                kannst du gerne eine Angebotsanfrage machen. Eine typische
                Spielflächen ist zwischen 300-400 m2. Individuelle Spiele und
                Anpassungen sind möglich.
              </p>
              <OutboundLink href="https://kartgames.ch">
                <div
                  className="btn btn-danger btn-rounded btn-lg"
                  style={{ fontSize: "2em" }}
                >
                  <i className="fas fa-forward" />
                  {"  "}kartgames.ch{"  "}
                </div>
              </OutboundLink>
            </div>{" "}
            {/* /.col-inner */}
          </div>{" "}
          {/* /.col */}
        </div>{" "}
        {/* /.row */}
      </div>{" "}
      {/* /.container */}
    </section>
  )
}
