import React, { Component } from "react"

import kartrace from "../assets/img/partners/kartrace-logo.jpg"

export default class Partners extends Component {
  render() {
    return (
      <section id="section-partners" className="partner no-padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col col-lg-12">
              <div className="col-inner">
                {/* Begin Heading */}
                <div className="heading heading-xlg heading-center">
                  <h1>
                    <span className="bg-main text-white">Partner</span>
                  </h1>
                </div>
                {/* End Heading */}
                <br />
                <div className="text-center max-width-900 margin-auto">
                  <p className="lead-theme">
                    der grosse Bruder im{" "}
                    <a
                      href="https://hallenstadion.ch"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Hallenstadion
                    </a>
                  </p>
                  <ul>
                    <li>
                      <a
                        href="https://kartrace.ch/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="sponsor-image"
                          title="Kartrace im Hallenstadion"
                          alt="kartrace"
                          src={kartrace}
                        />
                      </a>
                    </li>
                  </ul>
                  <br />
                </div>
              </div>{" "}
              {/* /.col-inner */}
            </div>{" "}
            {/* /.col */}
          </div>{" "}
          {/* /.row */}
        </div>{" "}
        {/* /.container */}
      </section>
    )
  }
}
